import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { processResponseAPI } from "../apis/responses.apis";

const Score = () => {
	const [isLoading, setisLoading] = useState(false);
	const [isSuccess, setisSuccess] = useState(true);
	const [isError, setisError] = useState(false);
	const [score, setscore] = useState(null);
	const { id: quizId } = useParams();

	const navigate = useNavigate();

	const handleEnd = () => {
		navigate("/");
	};

	return (
		<section className="bg-dark text-white">
			<div className="container">
				<div className="row vh-100 align-items-center justify-content-center">
					<div className="col-lg-6">
						{isLoading && <h3 className="mb-3 fw-bold">Please wait....</h3>}
						{!isLoading && (
							<div
								className={`text-light text-center p-5 rounded ${
									isSuccess ? "bg-success" : isError ? "bg-danger" : "bg-info"
								}`}>
								{isSuccess && (
									<div>
										<h1 className="mb-2 fw-bold">
											Thank you for taking the test!
										</h1>
										<h3 className="mb-3 fw-bold">You scored: {score}</h3>
										<button
											onClick={handleEnd}
											className="btn py-2 px-4 btn-light fw-bold d-inline">
											Go To Dashboard
										</button>
									</div>
								)}
								{isError && (
									<>
										<h3 className="mb-3 fw-bold">
											Failed To Fetch Your Score.
										</h3>
									</>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Score;
