import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";
import AuthProvider from "./context/AuthProvider";

import QuizTest from "./pages/QuizTest";
import Home from "./pages/Home";
import SignUp from "./pages/Signup";
import Login from "./pages/Login";
import StartQuiz from "./pages/StartQuiz";
import Processing from "./pages/Processing";
import Score from "./pages/score";
import Results from "./pages/Results";
import ResultDetails from "./pages/ResultDetails";
import TestLink from "./pages/TestLink";
import CoorporateRegister from "./pages/corporate/register";
import CoorporateLogin from "./pages/corporate/Login";
import CorpPages from "./pages/corporate/index";

import HomePageLayout from "./pages/HomePageLayout";

import user_types from "./constants/user_types";

function App() {
	return (
		<AuthProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HomePageLayout />} />
					<Route path="/register" element={<SignUp />} />
					<Route path="/login" element={<Login />} />
					<Route path="/tk/:testIdentifier" element={<TestLink />} />

					<Route path="/corp-auth">
						<Route path="register" element={<CoorporateRegister />} />
						<Route path="login" element={<CoorporateLogin />} />
					</Route>

					<Route
						element={<ProtectedRoute accType={user_types.CORP} />}
						path="/corp">
						<Route index element={<CorpPages.Dashboard />} />
						<Route path="test/create" element={<CorpPages.CreateTest />} />
						<Route
							path="restults/:testIdentifier"
							element={<CorpPages.Results />}
						/>
					</Route>

					<Route element={<ProtectedRoute accType={user_types.USER} />}>
						<Route path="/dashboard" element={<Home />} />
						<Route path="/results" element={<Results />} />
						<Route path="/results/:id" element={<ResultDetails />} />
						<Route path="/quiz/:id/start" element={<StartQuiz />} />
						<Route path="/quiz/:id/appearing" element={<QuizTest />} />
						<Route path="/quiz/:id/processing" element={<Processing />} />
						<Route path="/quiz/:id/score" element={<Score />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
