import React, { useState } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import { login } from "../../apis/users.apis";
import { useAuth } from "../../context/AuthProvider";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import Select from "../../components/ui/Select";

import { Label } from "../../components/ui/label";

import { register } from "../../apis/corp.apis";
import CorpLayout from "../../layouts/CorpLayout";
import Badge from "../../components/ui/Badge";

const defaultQuesObj = {
	id: 2,
	question:
		"Do you confirm to handle unexpected challenges and uncertainties in your work?",
	set_id: 1,
	info: "To Answer - speak your choice loudly and then logically explain your choice.",
	options: [
		// {
		// 	id: 6,
		// 	text: "Strongly Disagree",
		// },
		// {
		// 	id: 7,
		// 	text: "Disagree",
		// },
		// {
		// 	id: 8,
		// 	text: "Neutral",
		// },
		// {
		// 	id: 9,
		// 	text: "Agree",
		// },
		// {
		// 	id: 10,
		// 	text: "Strongly Agree",
		// },
	],
};

function CreateTest() {
	const [isloading, setisloading] = useState(false);

	const [quesState, setquesState] = useState([defaultQuesObj]);

	const [formState, setformState] = useState({
		corp_email: "",
		corp_first_name: "",
		corp_last_name: "",
		password: "",
		corp_job_tile: "",
		corp_corp_name: "",
		corp_emp_size: "",
		corp_phone_no: "",
		corp_region: "",
	});

	const addQuestionHandler = () => {
		if (quesState.length === 15) {
			alert("Only 15 question are allowed!");
			return;
		}

		const newObj = { ...defaultQuesObj };

		newObj.id = uuidv4();

		newObj.options = newObj.options.map((o) => ({ ...o, id: uuidv4() }));

		setquesState((prev) => [...prev, defaultQuesObj]);
	};

	const onChangeHandler = (e) => {
		const key = e.target.name;
		const value = e.target.value?.trim();

		if (value) {
			setformState((v) => ({ ...v, [key]: value }));
		}
	};

	const questionChangeHandler = (index, e) => {
		const text = e.target.value;

		setquesState((prev) => {
			const newState = [...prev];

			const qObj = newState[index];

			qObj.question = text;

			return newState;
		});
	};

	// const optionChangeHandler = (index, opIndex, e) => {
	// 	const text = e.target.value;

	// 	setquesState((prev) => {
	// 		const newState = [...prev];

	// 		const qObj = newState[index];

	// 		qObj.options[opIndex] = { ...qObj.options[opIndex], text };

	// 		return newState;
	// 	});
	// };

	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			setisloading(true);
			event.preventDefault();

			toast.success("registration was successfull!, Please login");

			navigate("/corp/login");
		} catch (error) {
			if (error.response?.data?.error) {
				toast.error(error.response?.data?.error);
				return;
			}

			toast.error("Something went wrong!");
		} finally {
			setisloading(false);
		}
	};

	const handleQuestRemove = (index) => {
		setquesState((prev) => prev.filter((v, i) => i !== index));
	};

	return (
		<CorpLayout>
			<section className="flex flex-col items-center justify-center">
				<h1 className="text-3xl md:text-3xl text-center lg:text-3xl font-bold mb-8 max-w-[1000px] text-white">
					Test Creation
				</h1>
				<div className="flex flex-col relative gap-6 items-center rounded-3xl  py-4 px-8 bg-white/50  border-t-2 shadow-sm border-white w-[80%]">
					<form onSubmit={handleSubmit} className="space-y-4 w-full">
						<div className="space-y-1 w-full flex items-center gap-x-1.5">
							<div className="flex-1">
								<Label htmlFor="module_name">Module Name</Label>
								<Input
									type="text"
									id="module_name"
									name="module_name"
									value={formState.module_name}
									onChange={onChangeHandler}
									required
								/>
							</div>
						</div>
						<div className="flex flex-wrap gap-2">
							{quesState.map((qS, index) => (
								<div
									key={qS.id}
									className="w-full sm:w-[calc(50%-8px)] p-2 border-2 border-violet-500 rounded">
									<div className="w-full">
										<Label htmlFor="corp_email">Question {index + 1}</Label>
										<Input
											type="text"
											value={qS.question}
											onChange={questionChangeHandler.bind(this, index)}
											required
										/>
									</div>
									{index !== 0 ? (
										<Badge
											className={"cursor-pointer"}
											onClick={handleQuestRemove.bind(this, index)}
											variant={"destructive"}>
											-
										</Badge>
									) : null}
									{/* <p className="mt-2 font-bold text-md">Options</p> */}
									{/* <div className="w-full flex flex-wrap">
										{qS.options.map((op, opIndex) => (
											<div className="sm:w-1/2">
												<Label htmlFor="corp_email">Option {opIndex + 1}</Label>
												<Input
													type="text"
													value={op.text}
													onChange={optionChangeHandler.bind(
														this,
														index,
														opIndex
													)}
													required
												/>
											</div>
										))}
									</div> */}
								</div>
							))}
						</div>

						<Button
							type="button"
							onClick={addQuestionHandler}
							className="bg-black mt-3">
							Add Question
						</Button>

						<div className="w-full flex justify-end">
							<Button type="submit" onClick={addQuestionHandler}>
								Save
							</Button>
						</div>
					</form>
				</div>
			</section>
		</CorpLayout>
	);
}

export default CreateTest;
