import React from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import useSWR from "swr";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

import HomePageHeader from "../../components/HomePageHeader";
import { getResults } from "../../apis/corp.apis";
import UserLayout from "../../layouts/UserLayout";

// const data = {
// 	id: 1,
// 	org_name: "House Of Couton",
// 	score: 8,
// 	date: "9PM, 12 Feb 2024",
// };

const Results = () => {
	const { testIdentifier } = useParams();

	const { data, error, isLoading } = useSWR(
		testIdentifier ? "/corporate/score_history_data/" : null,
		testIdentifier ? () => getResults(testIdentifier) : null
	);

	return (
		<UserLayout>
			<section
				style={{
					minHeight: "100vh",
				}}
				className="text-white">
				<div className="container">
					<h1 className="mb-4 text-center text-4xl font-bold">Results</h1>
					{isLoading ? (
						<center>
							<Spinner /> <span>Loading...</span>
						</center>
					) : null}

					{data ? (
						<>
							<div className="shadow-sm w-full p-2 rounded-3xl bg-gradient-to-b from-slate-50 to-slate-200">
								<table class="bg-transparent w-full border-collapse">
									<thead>
										<tr>
											<th class="text-black px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												Test Id
											</th>

											<th class="text-black px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												First Name
											</th>
											<th class="text-black px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												Last Name
											</th>

											<th class="text-black px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												Email
											</th>
											<th class="text-black px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												Score
											</th>
											<th class="text-black px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												Prediction
											</th>
											<th class="text-black px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												Attempted At
											</th>
											<th class=" text-black px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												View Complete Details
											</th>
										</tr>
									</thead>

									<tbody>
										{data.map((d) => (
											<tr className="text-black" key={d.id}>
												<td className="text-black border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
													{d.id}
												</td>
												<td className="text-black border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
													{d.first_name}
												</td>
												<td className="text-black border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
													{d.last_name}
												</td>
												<td className="text-black border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
													{d.email}
												</td>
												<td className=" text-blackborder-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
													{d.total_score}
												</td>
												<td className=" text-blackborder-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
													{d.model_prediction}
												</td>
												<td className="text-black border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
													{new Date(data.created_at).toDateString()}
												</td>
												<td className="text-black border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
													<Link
														to={"/results/" + data.user_id}
														className="text-black">
														View Details
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</>
					) : (
						<center>
							<h6>
								No Data Found. If you have just given the test, your data must
								be processing.
							</h6>
						</center>
					)}
				</div>
			</section>
		</UserLayout>
	);
};

export default Results;
