import {
	Dialog as MainDailog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "./DialogComponets";

const Dialog = ({ renderTrigger, title, children }) => {
	return (
		<MainDailog>
			<DialogTrigger asChild>{renderTrigger?.()}</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{title}</DialogTitle>
					<DialogDescription>{children}</DialogDescription>
				</DialogHeader>
			</DialogContent>
		</MainDailog>
	);
};

export default Dialog;
