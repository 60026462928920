import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "../components/ui/button";
import { copyToClipboard } from "../utils/helpers.utils";
import { toast } from "react-toastify";
import { BASE_URL } from "../constants/globals";
import { updateTestStatus } from "../apis/corp.apis";

const AssesmentTable = ({ rows = [], onStatusChange }) => {
	const [loadingID, setLoadingID] = useState(null);

	const handleCopyLink = (id) => {
		const link = BASE_URL + "tk/" + id;

		copyToClipboard(
			link,
			() => toast.success("Copied Successfully!"),
			() => toast.error("Failed to copy!")
		);
	};

	const handleChangeStatus = async (test_id) => {
		if (!window.confirm("Are you sure you want to update the status?")) {
			return;
		}

		try {
			setLoadingID(test_id);

			await updateTestStatus(test_id);

			toast.success("Status updated!");

			onStatusChange?.();
		} catch (error) {
			toast.error("Something went wrong!");
		} finally {
			setLoadingID(null);
		}
	};

	return (
		<div className="shadow-sm w-full p-2 rounded-3xl bg-gradient-to-b from-slate-50 to-slate-200 max-w-full overflow-x-scroll">
			<table class="bg-transparent w-full border-collapse">
				<thead>
					<tr>
						<th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 lg:text-md text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
							Test Id
						</th>
						<th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 lg:text-md text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
							Module Name
						</th>

						<th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 lg:text-md text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left max-w-4xl">
							Link
						</th>
						<th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 lg:text-md text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
							View Result
						</th>

						<th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 lg:text-md text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
							Change Status
						</th>

						<th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 lg:text-md text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
							Date
						</th>
					</tr>
				</thead>

				<tbody>
					{rows.map((v) => (
						<tr key={v.id}>
							<th class="border-t-0 px-6 align-middle border-l-0 border-r-0 lg:text-md text-xs whitespace-nowrap p-4 text-left text-blueGray-700 max-w-full">
								{v.id}
							</th>
							<td class="border-t-0 px-6 align-middle border-l-0 border-r-0 lg:text-md text-xs whitespace-nowrap p-4">
								{v.module_name}
							</td>
							<td class="border-t-0 px-6 align-middle border-l-0 border-r-0 lg:text-md text-xs whitespace-nowrap p-4">
								<Button
									variant="ghost"
									onClick={handleCopyLink.bind(this, v.test_identifier)}>
									Copy Link
								</Button>
							</td>
							<td class="border-t-0 px-6 align-center border-l-0 border-r-0 lg:text-md text-xs whitespace-nowrap p-4">
								<Link
									className="underline"
									to={`/corp/restults/${v.test_identifier}`}>
									View Results
								</Link>
							</td>
							<td class="border-t-0 px-6 align-center border-l-0 border-r-0 lg:text-md text-xs whitespace-nowrap p-4">
								<Button
									variant={v.status === "active" ? "destructive" : "default"}
									onClick={handleChangeStatus.bind(this, v.test_identifier)}
									disabled={loadingID === v.test_identifier}>
									{v.status === "active" ? "Deactivate" : "Activate"}
								</Button>
							</td>

							<td class="border-t-0 px-6 align-center border-l-0 border-r-0 lg:text-md text-xs whitespace-nowrap p-4">
								{new Date(v.created_at).toDateString()}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default AssesmentTable;
