import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import { useAuth } from "../context/AuthProvider";
import user_types from "../constants/user_types";

const AUTH_PATH = {
	[user_types.CORP]: "/corp-auth/login",
	[user_types.USER]: "/login",
};

const ProtectedRoute = ({ accType = user_types.USER }) => {
	const authState = useAuth();

	if (!authState.isAuth || authState.accType !== accType) {
		return <Navigate to={AUTH_PATH[accType]} />;
	}
	return <Outlet />;
};

export default ProtectedRoute;
