import {
	ArrowRightIcon,
	BorderRightIcon,
	CheckCircledIcon,
	CheckIcon,
	Cross1Icon,
	Cross2Icon,
} from "@radix-ui/react-icons";
import { Button } from "./ui/button";

const PricingPlans = () => {
	return (
		<div className="flex justify-center items-center">
			<div className="flex flex-col md:flex-row justify-center items-center gap-4">
				<div className="bg-white/50  border-[white] border-[0.08px]  p-6 rounded-lg shadow-lg w-80  backdrop-blur-md">
					<div className="absolute -inset-[10px] -z-10 bg-white opacity-20 blur-3xl  transition group-hover:duration-300 duration-1000 group-hover:opacity-40 group-hover:  " />

					<div className="flex justify-center mb-4">
						<div className="w-32 h-32">
							{/* Replace with your illustration */}
							<img src="/sub2.png" alt="Professional Illustration" />
						</div>
					</div>
					<h2 className="text-2xl font-bold mb-4 -mt-8">Starter</h2>
					<p className="text-4xl font-bold mb-4">Free</p>
					<ul className="mb-4">
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								{" "}
								<CheckIcon />
							</span>
							Unlimited files in draft
						</li>
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								{" "}
								<CheckIcon />
							</span>
							Unlimited viewers and commenters
						</li>
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								{" "}
								<CheckIcon />
							</span>
							Unlimited editors on 3 team files
						</li>
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								<Cross2Icon />
							</span>
							1 team project
						</li>
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								<Cross2Icon />
							</span>
							30-day version history
						</li>
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								<Cross2Icon />
							</span>
							Unlimited cloud storage
						</li>
					</ul>
					<Button className="w-full ">Get Started</Button>
				</div>
				<div className="bg-[#32BAFF]/30 border-[0.08px] border-[#32BAFF]   text-white backdrop-blur-3xl p-6 rounded-lg shadow-lg w-80">
					<div className="absolute -inset-[10px] -z-10 bg-white opacity-20 blur-3xl  transition group-hover:duration-300 duration-1000 group-hover:opacity-40 group-hover:  " />

					<div className="flex justify-center mb-4">
						<div className="w-32 h-32">
							{/* Replace with your illustration */}
							<img src="/sub1.png" alt="Professional Illustration" />
						</div>
					</div>
					<h2 className="text-2xl font-bold mb-4  -mt-8">Professional</h2>
					<p className="text-4xl font-bold mb-4">&#x20b9;25,000</p>
					<ul className="mb-4">
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								{" "}
								<CheckIcon />
							</span>
							Unlimited files in draft
						</li>
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								{" "}
								<CheckIcon />
							</span>
							Unlimited viewers and commenters
						</li>
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								{" "}
								<CheckIcon />
							</span>
							Unlimited editors on 3 team files
						</li>
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								{" "}
								<CheckIcon />
							</span>
							1 team project
						</li>
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								{" "}
								<CheckIcon />
							</span>
							30-day version history
						</li>
						<li className="flex items-center mb-2">
							<span className="material-icons mr-2">
								{" "}
								<CheckIcon />
							</span>
							Unlimited cloud storage
						</li>
					</ul>
					<Button className="w-full">Contact Us </Button>
				</div>
			</div>
		</div>
	);
};

export default PricingPlans;
