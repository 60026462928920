import React from "react";

import { useState, useCallback } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { toast } from "react-toastify";

import { getQuestions, getAnswerTime } from "../apis/quizes.apis";
import { processResponseAPI } from "../apis/responses.apis";

import Quiz from "../components/Quiz";
import BgCover from "../components/BgCover";

const QuizTest = () => {
	const { id: quizId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const qParam = parseInt(searchParams.get("q"));
	const paramQuestionId = isNaN(qParam) ? 0 : qParam;

	const [questionIndex, setQuestionIndex] = useState(0);

	const answerTimeState = useSWR("/answer_time", getAnswerTime);

	const { data: quizs, isLoading } = useSWR(
		quizId ? `/quiz/${quizId}` : null,
		getQuestions,
		{
			onSuccess: (data) => {
				const qIndex =
					data && paramQuestionId
						? data.findIndex((v) => v.id === paramQuestionId)
						: 0;

				if (qIndex > 0) {
					setQuestionIndex(qIndex);
					setSearchParams("");
				}
			},
		}
	);

	const nextQuestion = useCallback(async () => {
		setQuestionIndex((pi) => {
			const newIndex = pi + 1;

			//all questions has been answered!
			if (newIndex === quizs.length) {
				processResponseAPI()
					.then(() => null)
					.catch(() => null);
				// window.location.href = "/quiz/" + quizId + "/processing";
				navigate("/quiz/" + quizId + "/processing");
				return 0;
			}

			toast.success("Question No." + newIndex + " Saved successfully!");
			return newIndex;
		});
	}, [quizId, navigate, quizs?.length]);

	const question = quizs?.[questionIndex] ? quizs[questionIndex] : {};

	const activeQuesNo = quizs?.indexOf(question) + 1;
	const totalQues = quizs?.length;

	if (isLoading || answerTimeState.isLoading) return <div>Loading...</div>;

	if (!quizs || !answerTimeState.data?.time) {
		return <h1>No Data Found!</h1>;
	}

	return (
		<div>
			<BgCover />
			<Quiz
				activeQuesNo={activeQuesNo}
				totalQues={totalQues}
				question={question}
				nextQuestion={nextQuestion}
				timeIsSec={answerTimeState.data?.time || 25}
			/>
		</div>
	);
};

export default QuizTest;
