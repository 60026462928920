import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";

import { Button } from "./ui/button";

const UserNavbar = () => {
	const { logOut } = useAuth();
	const navigate = useNavigate();

	const handleLogOut = () => {
		logOut();
		navigate("/login");
	};

	const handleResultClick = () => {
		navigate("/results");
	};

	return (
		<nav className="bg-white shadow shadow-gray-300 w-100 px-8 md:px-auto">
			<div className="md:h-16 h-28 mx-auto md:px-4 container flex items-center justify-between flex-wrap md:flex-nowrap">
				<Link to="/dashboard" className="flex gap-2 text-indigo-500 md:order-1">
					<img
						width={30}
						height={30}
						style={{
							// mixBlendMode: "lighten",
							backgroundColor: "transparent",
						}}
						alt="Ai Hire Studio Logo"
						src="/logo192.png"
					/>
					<h1 className="text-xl font-bold">AI Hire Studio</h1>
				</Link>
				{/* <div className="text-gray-500 order-3 w-full md:w-auto md:order-2">
					<ul className="flex font-semibold justify-between">
							<li className="md:px-4 md:py-2 text-indigo-500">
								<Link to={"/"}>Dashboard</Link>
							</li>
							<li className="md:px-4 md:py-2 hover:text-indigo-400">
								<Link href="#">Search</Link>
							</li>
							<li className="md:px-4 md:py-2 hover:text-indigo-400">
								<Link href="#">Explore</Link>
							</li>
							<li className="md:px-4 md:py-2 hover:text-indigo-400">
								<Link href="#">About</Link>
							</li>
							<li className="md:px-4 md:py-2 hover:text-indigo-400">
								<Link href="#">Contact</Link>
							</li>
						</ul>
				</div> */}
				<div className="order-2 md:order-3 flex gap-2">
					<Button variant="default" onClick={handleResultClick}>
						Results
					</Button>
					<Button variant="destructive" onClick={handleLogOut}>
						Logout
					</Button>
				</div>
			</div>
		</nav>
	);
};

export default UserNavbar;
