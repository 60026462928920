import axios from "../libs/axios.lib";
import * as secureStorage from "../utils/storage.utils";

export const saveResponse = async (
	body = { questionId: 0, selectedOptionId: 0 }
) => {
	return;
	// const { token } = secureStorage.getUser();
	// await axios.post(`/responses`, body, {
	// 	headers: {
	// 		Authorization: "Bearer " + token,
	// 	},
	// });
};

export const saveAudioRecAPI = async (fd) => {
	const { token } = secureStorage.getUser();
	await axios.post(`user/user-responses/audio`, fd, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});
};

export const saveVideoRecAPI = async (fd) => {
	const { token } = secureStorage.getUser();
	await axios.post(`user/user-responses/video`, fd, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});
};

export const processResponseAPI = async (quizId) => {
	const { token, id } = secureStorage.getUser();
	const testData = secureStorage.getTestData();

	const { data } = await axios.get(
		`/user/score_generator/${id}/${testData.company_name}/${testData.test_identifier}`,
		{
			headers: {
				Authorization: "Bearer " + token,
			},
		}
	);

	return data;
};

export const getResult = async () => {
	const { token, id } = secureStorage.getUser();
	const { data } = await axios.get(`share_data/` + encodeURIComponent(id), {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data.message;
};

export const getScores = async () => {
	const { token, id } = secureStorage.getUser();
	const { data } = await axios.get(
		`/user/score_history_data/` + encodeURIComponent(id),
		{
			headers: {
				Authorization: "Bearer " + token,
			},
		}
	);

	return data.data;
};
