import React, { useState } from "react";
import useSWR from "swr";
import { useNavigate, Navigate, Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { register } from "../apis/users.apis";
import { useAuth } from "../context/AuthProvider";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";

import { getTestIdentifierData } from "../apis/quizes.apis";

function Registration() {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isloading, setisloading] = useState(false);

	const [searchParams] = useSearchParams();

	const testIdentifier = searchParams.get("test_identifier");

	const { data: testData } = useSWR(
		testIdentifier ? `/testIdentifier/${testIdentifier}` : null,
		() => getTestIdentifierData(testIdentifier)
	);

	const { isAuth } = useAuth();
	const navigate = useNavigate();

	const loginPath = "/login" + window.location.search;

	const handleSubmit = async (event) => {
		try {
			setisloading(true);
			event.preventDefault();

			// Perform validations
			if (
				!firstName.trim() ||
				!lastName.trim() ||
				!email.trim() ||
				!password.trim()
			) {
				toast.error("Please fill in all required fields.");
				return;
			}

			// Validate email format
			if (!email.includes("@")) {
				toast.error("Please enter a valid email address.");
				return;
			}

			await register({
				email: email.trim(),
				password: password.trim(),
				first_name: firstName.trim(),
				last_name: lastName.trim(),
				company_name: testData?.company_name ? testData?.company_name : "None",
			});

			toast.success("Registration successful!");

			navigate(loginPath);
		} catch (error) {
			if (error.response?.data?.error) {
				toast.error(error.response?.data?.error);
				return;
			}

			toast.error("Something went wrong!");
		} finally {
			setisloading(false);
		}
	};

	if (isAuth) {
		return <Navigate to={"/dashboard"} />;
	}

	return (
		<section className="min-h-screen bg-gradient-to-b from-slate-50 to-slate-200 flex items-center justify-center">
			<div className="flex flex-col relative gap-6 items-center rounded-3xl py-4 px-8 bg-white/50 border-t-2 shadow-sm border-white">
				{/* <Button className="flex mb-1 px-14 py-6 rounded-lg items-center justify-center w-[80%] text-md h-11 gap-2 bg-white text-black/70 font-medium">
					<img src="/google.svg" alt="googleicon" className="h-5 w-5" />{" "}
					Continue with Google
				</Button> */}
				{/* <div className="flex items-center">
					<div className="w-[100px] h-[1px] bg-slate-500/20"></div>
					<p className="px-3">OR</p>
					<div className="w-[100px] h-[1px] bg-slate-500/20"></div>
				</div> */}
				<form onSubmit={handleSubmit} className="space-y-3">
					<div className="space-y-1">
						<Label htmlFor="firstName">First Name</Label>
						<Input
							type="text"
							id="firstName"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
					</div>
					<div className="space-y-1">
						<Label htmlFor="lastName">Last Name</Label>
						<Input
							type="text"
							id="lastName"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
					</div>
					<div className="space-y-1">
						<Label htmlFor="email">Email</Label>
						<Input
							type="email"
							id="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="space-y-1">
						<Label htmlFor="password">Password</Label>
						<Input
							type="password"
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							minLength={5}
						/>
					</div>
					<Button className="bg-black mt-3" type="submit" disabled={isloading}>
						{isloading ? "Loading..." : "Register"}
					</Button>
				</form>
				<div>
					<span className="text-sm text-slate-600">
						Already have an account?
					</span>{" "}
					<span>
						<Link to={loginPath} className="text-sm font-medium text-zinc-700">
							Login here.
						</Link>
					</span>
				</div>
			</div>
		</section>
	);
}

export default Registration;
