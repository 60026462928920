import React, { useState, useRef, useEffect, useCallback } from "react";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { useRecordWebcam } from "react-record-webcam";
import { toast } from "react-toastify";
import Webcam from "react-webcam";

import useTimerHK from "../hk/useTimer";

import { saveVideoRecAPI } from "../apis/responses.apis";

import useFaceDetection from "../hk/useFaceDetection";
import TestNav from "./TestNav";

const Quiz = ({
	question,
	activeQuesNo,
	totalQues,
	nextQuestion,
	timeIsSec,
}) => {
	const [showHelperText, setShowHelperText] = useState(true);
	const [isSaving, setisSaving] = useState(false);

	const rwRef = useRef(null);
	const qIdRef = useRef(question.id);
	const isFirstDetecttionDoneRef = useRef(false);

	const { isDetected, instructions } = useFaceDetection();

	const rw = useRecordWebcam({
		options: { fileType: "mp4" },
		mediaRecorderOptions: { mimeType: "video/mp4" },
		mediaTrackConstraints: { video: true, audio: true },
		aspectRatio: 0.56,
	});

	qIdRef.current = question.id;

	const stopRecording = async () => {
		try {
			setisSaving(true);

			const recorded = await rw.stopRecording(rwRef.current.id);
			const formData = new FormData();

			formData.append("video", recorded.blob, "recording.mp4");
			formData.append("question_id", qIdRef.current);

			await saveVideoRecAPI(formData);
			// await rw.download(rwRef.current.id);

			nextQuestion();
			startRecording();
		} catch (error) {
			toast.error("unable to save the response!");
			console.log(error);
		} finally {
			setisSaving(false);
		}
	};

	const { startTimer } = useTimerHK(timeIsSec, stopRecording);

	const startRecording = useCallback(async () => {
		if (rwRef.current?.id) {
			await rw.clearAllRecordings();
		}

		const recording = await rw.createRecording();

		if (!recording) {
			return;
		}

		await rw.openCamera(recording.id);
		await rw.startRecording(recording.id);

		rwRef.current = recording;

		startTimer();

		isFirstDetecttionDoneRef.current = true;
	}, [rw, startTimer]);

	useEffect(() => {
		if (!isSaving && isDetected && !isFirstDetecttionDoneRef.current) {
			startRecording();
		}
	}, [isSaving, isDetected, startRecording]);

	return (
		<div>
			<TestNav stopRecording={stopRecording} />
			<section className="min-h-[90vh] text-white relative">
				<div className="mt-4 h-[100%] flex justify-center items-center">
					<div className="container">
						{showHelperText && (
							<Alert
								variant={"info"}
								onClose={() => setShowHelperText(false)}
								dismissible>
								Your response will be recorded through voice recognition. No
								clicking is required. Just Say Your Answer Out Loud.
							</Alert>
						)}
						<div className="flex flex-col lg:flex-row gap-2 items-stretch">
							<div className="order-2 md:order-1 flex-0.5">
								<div>
									{rw.activeRecordings.length > 0 ? (
										<Webcam
											className="rounded-xl w-64 sm:w-auto shadow-xl"
											videoConstraints={{ facingMode: "user" }}
											style={{
												width: "200px",
												height: "200px",
											}}
											ref={rw.activeRecordings[0].webcamRef}
										/>
									) : null}
								</div>
							</div>
							<div className="order-1 md:order-2 flex-1">
								{isSaving ? (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											gap: "8px",
										}}>
										<Spinner animation="border" role="status"></Spinner>
										<h1>Saving your response...</h1>
									</div>
								) : (
									<div className="text-black shadow-sm w-full p-2 rounded-3xl bg-gradient-to-b from-slate-50 to-slate-200 p-4">
										<h4 className="text-danger font-bold">
											<span id={"timer-span"}></span>
										</h4>

										<div className="d-flex justify-content-between gap-md-3">
											<h5 className="mb-2 fs-normal lh-base">
												{question?.question}
												<strong style={{ fontWeight: 800 }}>
													{question?.info ? ` (${question.info})` : ""}
												</strong>
											</h5>
											<h5
												className="text-indigo-500 font-bold"
												style={{
													width: "100px",
													textAlign: "right",
												}}>
												{activeQuesNo} / {totalQues}
											</h5>
										</div>
										<div className="mt-3">
											{question?.options?.map((item, index) => (
												<button
													key={index}
													id={"opt-btn-" + item.id}
													className={`bg-primary w-full text-left text-primary-foreground shadow my-2 p-2 rounded`}
													disabled>
													{item.text}
												</button>
											))}
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				{/* modal */}
				{!isDetected ? (
					<div className="w-full absolute top-0 bg-purple-800 bg-opacity-80 p-3">
						<div className="text-center text-neutral-content">
							<div className="">
								<h1 className="sm:text-5xl text-2xl text-white font-bold mb-2">
									Face validation failed! Please be in center of the camera in
									light area
								</h1>
								<div className="py-6 shadow-sm flex justify-center">
									<Webcam
										className="rounded-xl w-64 sm:w-auto shadow-xl"
										videoConstraints={{ facingMode: "user" }}
									/>
								</div>
								<p className="sm:text-xl text-lg font-bold text-red-500">
									{instructions}
								</p>
							</div>
						</div>
					</div>
				) : null}
			</section>
		</div>
	);
};

export default Quiz;
