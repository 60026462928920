import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";
import { MediaPermissionsErrorType, requestMediaPermissions } from "mic-check";

import { Button } from "../components/ui/button";
import TestNav from "../components/TestNav";
import Modal from "react-bootstrap/Modal";

import { getFileCounts, deleteVideoFiles } from "../apis/quizes.apis";
import BgCover from "../components/BgCover";

import * as storageUtils from "../utils/storage.utils";

const StartQuiz = () => {
	const [isLoading, setisLoading] = useState(false);

	const testData = storageUtils.getTestData();

	const { id: quizId } = useParams();
	const navigate = useNavigate();

	const [show, setShow] = useState({
		open: false,
		title: "",
		desc: "",
	});

	const {
		isLoading: isFetching,
		error,
		data: fileCount,
	} = useSWR("/count", getFileCounts);

	const handleClose = () =>
		setShow({
			open: false,
			title: "",
			desc: "",
		});

	const handleResume = () => {
		navigate("/quiz/" + quizId + "/appearing?q=" + (fileCount + 1));
	};

	const handleRetake = async () => {
		try {
			setisLoading(true);
			await deleteVideoFiles();
			navigate("/quiz/" + quizId + "/appearing");
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong while restarting the test!");
		} finally {
			setisLoading(false);
		}
	};

	const handleStart = () => {
		setisLoading(true);

		requestMediaPermissions({
			audio: true,
			video: true,
		})
			.then(() => {
				// if (fileCount === 30) {
				// 	setShow({
				// 		open: true,
				// 		title: "Retake Test?",
				// 		desc: "You have already attempted this test, if you want you can attempt it again, however retaking your test will erase all of your previous response and this action cannot be undone!",
				// 		status: "completed",
				// 	});

				// 	return;
				// }

				// if (fileCount) {
				// 	setShow({
				// 		open: true,
				// 		title: "Resume Test?",
				// 		desc: "we see you discontinued the test in between, want to resume?",
				// 		status: "resume",
				// 	});
				// 	return;
				// }

				navigate("/quiz/" + quizId + "/appearing");
			})
			.catch((err) => {
				const { type, name, message } = err;
				if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
					toast.error(
						"Browser does not have permission to access camera or microphone"
					);
				} else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
					toast.error("didn't allow app to access camera or microphone");
				} else if (
					type === MediaPermissionsErrorType.CouldNotStartVideoSource
				) {
					toast.error(
						"camera is in use by another application or browser tab."
					);
				} else {
					toast.error("we are having trouble accessing the video and audio.");
				}
			})
			.finally((v) => {
				setisLoading(false);
			});
	};

	return (
		<div>
			<BgCover />
			<TestNav />
			<section className="text-center">
				<div className="container">
					<div className="row vh-100 align-items-center justify-content-center">
						<div className="col-lg-8">
							<div className="mb-4">
								<h1 className="text-white font-bold text-4xl mb-2">
									Attempting test for : {testData.company_name}
								</h1>
								<h1 className="text-white font-bold text-2xl mb-2">
									Test Name: {testData.module_name}
								</h1>
								<h5 className="text-white text-xl my-2">
									Start the test by clicking below button
								</h5>
								<h1 className="text-white font-bold text-xl my-2">
									All The Best!
								</h1>
							</div>
							<Button size="lg" onClick={handleStart} disabled={isLoading}>
								{isLoading || isFetching ? "Loading..." : "Start Test"}
							</Button>
						</div>
					</div>
				</div>
				<>
					<Modal show={show.open} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>{show.title}</Modal.Title>
						</Modal.Header>
						<Modal.Body>{show.desc}</Modal.Body>
						<Modal.Footer>
							<Button
								// variant="danger"
								disabled={isLoading}
								onClick={handleRetake}>
								Retake Test
							</Button>
							{show.status === "resume" ? (
								<Button onClick={handleResume}>Resume Test</Button>
							) : null}
						</Modal.Footer>
					</Modal>
				</>
			</section>
		</div>
	);
};

export default StartQuiz;
