import { useEffect, useState, useRef } from "react";
import * as faceapi from "face-api.js";

export const useFaceDetection = () => {
	const intervalRef = useRef();
	const [isDetected, setIsDetected] = useState(false);
	const [instructions, setInstructions] = useState(
		"Ensure your face is clearly visible"
	);
	const detectionCounterRef = useRef(0);
	const nonCenterCounterRef = useRef(0);

	useEffect(() => {
		const loadModels = async () => {
			try {
				await Promise.all([
					faceapi.nets.tinyFaceDetector.loadFromUri(
						"https://test-models.pages.dev/"
					),
					faceapi.nets.faceLandmark68Net.loadFromUri(
						"https://test-models.pages.dev/"
					),
					faceapi.nets.faceRecognitionNet.loadFromUri(
						"https://test-models.pages.dev/"
					),
					faceapi.nets.faceExpressionNet.loadFromUri(
						"https://test-models.pages.dev/"
					),
				]);
				startFaceDetection();
			} catch (error) {
				console.error("Error loading face-api models:", error);
			}
		};

		const startFaceDetection = async () => {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					video: true,
				});
				const video = document.createElement("video");
				video.srcObject = stream;
				video.onloadedmetadata = () => {
					video.play();
					detectFace(video);
				};
				return () => {
					stream.getTracks().forEach((track) => track.stop());
					if (intervalRef.current) {
						cancelAnimationFrame(intervalRef.current);
					}
				};
			} catch (error) {
				console.error("Error accessing camera:", error);
			}
		};

		const detectFace = async (video) => {
			const detect = async () => {
				const detections = await faceapi
					.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
					.withFaceLandmarks();

				if (detections && detections.length === 1) {
					const face = detections[0];
					const { box } = face.detection;
					const centerX = (box.x + box.width / 2) / video.videoWidth;
					const centerY = (box.y + box.height / 2) / video.videoHeight;
					const centerXThreshold = 0.2; // More relaxed threshold
					const centerYThreshold = 0.2; // More relaxed threshold

					if (
						centerX > 0.5 - centerXThreshold &&
						centerX < 0.5 + centerXThreshold &&
						centerY > 0.5 - centerYThreshold &&
						centerY < 0.5 + centerYThreshold
					) {
						nonCenterCounterRef.current = 0; // Reset counter when face is centered
						setIsDetected(true);
						setInstructions("Face detected in center");
					} else {
						nonCenterCounterRef.current++; // Increment counter when face is not centered
						if (nonCenterCounterRef.current >= 10) {
							// 10 seconds of non-centered detection
							setIsDetected(false);
							setInstructions("Please center your face");
						}
					}
				} else if (detections.length > 1) {
					detectionCounterRef.current = 0; // Reset counter on multiple faces detected
					setIsDetected(false);
					setInstructions(
						"Multiple faces detected. Please ensure only one face is visible"
					);
				} else {
					nonCenterCounterRef.current++; // Increment counter on non-detection
					if (nonCenterCounterRef.current >= 10) {
						// 10 seconds of non-detection
						setIsDetected(false);
						setInstructions("No face detected");
					}
				}
				intervalRef.current = requestAnimationFrame(detect);
			};
			intervalRef.current = requestAnimationFrame(detect);
		};

		loadModels();

		return () => {
			if (intervalRef.current) {
				cancelAnimationFrame(intervalRef.current);
			}
		};
	}, []);

	return { isDetected, instructions };
};

export default useFaceDetection;
