import { Link } from "react-router-dom";
import PricingPlans from "../components/Pricing";
// import Subscription from "../components/Subscription";
import { Button } from "../components/ui/button";
import { motion } from "framer-motion";
function HomePage() {
	return (
		<main className="mt-28 flex flex-col items-center justify-center">
			<motion.div
				// initial={{ opacity: 0, scale: 0 }}
				// animate={{ opacity: 1, scale: 1 }}
				// transition={{ duration: 0.4 }}
				className="text-white">
				<div className="leading-loose text-5xl md:text-6xl text-center lg:text-8xl font-bold mb-8 max-w-[1000px]">
					<h1 className="">
						The best way to hire talents{" "}
						<span className="text-[#32BAFF]">using</span>{" "}
					</h1>
					<h1 className="capitalize">
						<span className="text-[#6666FF]">AI Hire</span>{" "}
						<span className="text-[#BA33FF]">Studio</span> App
					</h1>
				</div>
			</motion.div>
			<motion.p
				initial={{ opacity: 0, y: 25 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.4 }}
				className="max-w-prose text-zinc-300/60 sm:text-lg text-center">
				AI Studio is a cutting-edge application designed to optimize various
				aspects of the recruitment process through artificial intelligence.
				Leveraging advanced machine learning algorithms and natural language
				processing
			</motion.p>
			<motion.div
				initial={{ opacity: 0, y: 25 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.4, delay: 0.6 }}
				className=" space-x-4 text-center mt-8 ">
				<Button
					aschild
					className="py-6 relative rounded-full bg-[#E8DDFF] hover:bg-[#C2B4FC] text-black font-semibold text-md"
					size="lg">
					{/* <span className="absolute -z-10 rounded-full blur opcaity-30 inset-0 bg-gradient-to-r from-pink-300 to-purple-600 " /> */}
					<Link to="/register">Get Started</Link>
				</Button>
				<Button
					aschild
					className="py-6 relative rounded-full bg-[#332953] hover:bg-[#494165] text-white text-md font-semibold"
					size="lg"
					variant="secondary">
					{/* <span className="absolute -z-10 rounded-full blur opcaity-30 inset-1 bg-gradient-to-r from-pink-300 to-purple-600 " /> */}
					<Link to={"/login"}>Log In</Link>
				</Button>
			</motion.div>

			{/* <motion.div
				initial={{ opacity: 0, y: 25 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.4, delay: 0.9 }}
				className="w-full h-auto px-6 mt-24 relative rounded-lg group">
				<div className="">
					<img
						src="/image.png"
						alt="hero"
						className="pointer-events-none rounded-lg"
					/>
					<div className="absolute -z-10 -inset-[10px] rounded-xl border border-slate-100/20 bg-slate-200/10 backdrop-blur-md" />
					<motion.div className="absolute -inset-[30px] -z-10 bg-white opacity-30 blur-2xl  transition group-hover:duration-300 duration-1000 group-hover:opacity-40 group-hover:  " />
				</div>
			</motion.div> */}

			{/* <Subscription />
			 */}
			<div className="mt-32">
				<h1 className="text-center mb-4 leading-7 lg:text-6xl md:text-6xl sm:text-4xl text-white font-medium">
					Subscription <span className="font-bold text-[#32BAFF]">Plans</span>
				</h1>
				<PricingPlans />
			</div>
		</main>
	);
}

export default HomePage;
//
