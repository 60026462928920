import { cn } from "../../lib/utils";
import * as React from "react";

const Select = React.forwardRef(
	({ className, type, children, ...props }, ref) => {
		return (
			<select
				ref={ref}
				className={cn(
					"w-full h-9 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors",
					className
				)}
				{...props}>
				{children}
			</select>
		);
	}
);

Select.displayName = "Select";

export default Select;
