import React, { useState } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { login } from "../../apis/users.apis";
import { useAuth } from "../../context/AuthProvider";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import Select from "../../components/ui/Select";

import { Label } from "../../components/ui/label";

import { register } from "../../apis/corp.apis";

function Register() {
	const [isloading, setisloading] = useState(false);

	const [formState, setformState] = useState({
		corp_email: "",
		corp_first_name: "",
		corp_last_name: "",
		password: "",
		corp_job_tile: "",
		corp_corp_name: "",
		corp_emp_size: "",
		corp_phone_no: "",
		corp_region: "",
	});

	const onChangeHandler = (e) => {
		const key = e.target.name;
		const value = e.target.value?.trim();

		if (value) {
			setformState((v) => ({ ...v, [key]: value }));
		}
	};

	const navigate = useNavigate();
	const { isAuth } = useAuth();

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			setisloading(true);
			event.preventDefault();

			await register({
				...formState,
			});

			// setAuth({ ...d, isAuth: true });

			toast.success("registration was successfull!, Please login");

			navigate("/corp-auth/login");
		} catch (error) {
			if (error.response?.data?.error) {
				toast.error(error.response?.data?.error);
				return;
			}

			toast.error("Something went wrong!");
		} finally {
			setisloading(false);
		}
	};

	if (isAuth) {
		return <Navigate to={"/dashboard"} />;
	}

	return (
		<section className="min-h-screen bg-gradient-to-b from-slate-50 to-slate-200 flex flex-col items-center justify-center">
			<h1 className="text-5xl md:text-5xl text-center lg:text-5xl font-bold mb-8 max-w-[900px]">
				Thank you for choosing
				<span className="text-[#6666FF]"> AI</span>{" "}
				<span className="text-[#BA33FF]">Studio</span> App
			</h1>
			<h1 className="text-2xl md:text-2xl text-center lg:text-2xl font-bold mb-8 max-w-[900px]">
				Corporate Registration
			</h1>
			<div className="flex flex-col relative gap-6 items-center rounded-3xl  py-4 px-8 bg-white/50  border-t-2 shadow-sm border-white  ">
				<form onSubmit={handleSubmit} className="space-y-3">
					<div className="space-y-1 flex items-center gap-x-1.5">
						<div className="">
							<Label htmlFor="corp_first_name">First Name: </Label>
							<Input
								type="text"
								id="corp_first_name"
								name="corp_first_name"
								value={formState.corp_first_name}
								onChange={onChangeHandler}
								required
							/>
						</div>
						<div className="">
							<Label htmlFor="corp_last_name">Last Name: </Label>
							<Input
								type="text"
								id="corp_last_name"
								name="corp_last_name"
								value={formState.corp_last_name}
								onChange={onChangeHandler}
								required
							/>
						</div>
					</div>

					<div className="space-y-1 flex items-center gap-x-1.5">
						<div className="flex-1">
							<Label htmlFor="corp_email">Email: </Label>
							<Input
								type="text"
								id="corp_email"
								name="corp_email"
								value={formState.corp_email}
								onChange={onChangeHandler}
								required
							/>
						</div>
						<div className="flex-1 flex flex-col gap-y-1.5">
							<Label htmlFor="corp_job_tile">Job Title: </Label>
							<Select
								id="corp_job_tile"
								name="corp_job_tile"
								onChange={onChangeHandler}
								required
								className="w-full h-9 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors">
								<option value={""} selected>
									Select job role
								</option>
								<option value={"HR Manager"}>HR Manager</option>
								<option value="HR Associate">HR Associate</option>
								<option value="VC">VC</option>
								<option value="Founder">Founder</option>
							</Select>
						</div>
					</div>

					<div className="space-y-1">
						<Label htmlFor="password">Password</Label>
						<Input
							type="password"
							id="password"
							name="password"
							value={formState.password}
							onChange={onChangeHandler}
							required
						/>
					</div>

					<div className="space-y-1 flex items-center gap-x-1.5">
						<div className="flex-1">
							<Label htmlFor="corp_corp_name">Company Name:</Label>
							<Input
								type="text"
								id="corp_corp_name"
								name="corp_corp_name"
								value={formState.corp_corp_name}
								onChange={onChangeHandler}
								required
							/>
						</div>
						<div className="flex-1 flex flex-col gap-y-1.5">
							<Label htmlFor="corp_job_tile">Employees: </Label>
							<Select
								id="corp_emp_size"
								name="corp_emp_size"
								onChange={onChangeHandler}
								required
								className="w-full h-9 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors">
								<option value={""} selected>
									Select Employees
								</option>
								<option value="0-10">0-10</option>
								<option value="10-50">10-50</option>
								<option value="50-100">50-100</option>
								<option value="100-150">100-150</option>
								<option value="150-200">150-200</option>
							</Select>
						</div>
					</div>

					<div className="space-y-1 flex items-center gap-x-1.5">
						<div className="flex-1">
							<Label htmlFor="corp_phone_no">Phone:</Label>
							<Input
								type="tel"
								id="corp_phone_no"
								name="corp_phone_no"
								value={formState.corp_phone_no}
								onChange={onChangeHandler}
								required
							/>
						</div>
						<div className="flex-1 flex flex-col gap-y-1.5">
							<Label htmlFor="corp_region">Country/Region: </Label>
							<Select
								id="corp_region"
								name="corp_region"
								onChange={onChangeHandler}
								required
								className="w-full h-9 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors">
								<option value={""} selected>
									Select Region
								</option>
								<option value="india">india</option>
								<option value="America">America</option>
								<option value="UK">UK</option>
							</Select>
						</div>
					</div>

					<Button className="bg-black mt-3" type="submit" disabled={isloading}>
						{isloading ? "Loading..." : "Register"}
					</Button>
				</form>
				<div>
					<span className="text-sm text-slate-600">
						Already have an account?
					</span>{" "}
					<span>
						<Link
							to="/corp-auth/login"
							className="text-sm font-medium text-zinc-700">
							login here.
						</Link>
					</span>
				</div>
			</div>
		</section>
	);
}

export default Register;
