import React from "react";
import UserNavbar from "../components/UserNavbar";
import BgCover from "../components/BgCover";

const UserLayout = ({ children }) => {
	return (
		<main>
			<BgCover />
			<UserNavbar />
			<div className="p-2">{children}</div>
		</main>
	);
};

export default UserLayout;
