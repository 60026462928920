import BgCover from "../components/BgCover";
import HomePage from "./HomePage";

function HomePageLayout() {
	return (
		<>
			<BgCover />

			<div className="absolute -z-10 bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]"></div>
			<div className="max-w-[1050px] mx-auto flex px-2">
				<HomePage />
			</div>
		</>
	);
}

export default HomePageLayout;
