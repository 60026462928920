import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";

import CorpLayout from "../../layouts/CorpLayout";
import AssesmentTable from "../../components/AssesmentTable";
import { Button } from "../../components/ui/button";
import Dialog from "../../components/ui/Dialog";
import Select from "../../components/ui/Select";

import { getTests, createTest, getAllModules } from "../../apis/corp.apis";
import { toast } from "react-toastify";

const Dashboard = () => {
	const { isLoading, data, mutate } = useSWR("/corporate/get_test", getTests);
	const moduleListResp = useSWR("/get/modules", getAllModules);

	const [isSaving, setisSaving] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setisSaving(true);

			const fd = new FormData(e.target);
			const module = fd.get("module");

			await createTest(module?.trim());

			toast.success("Test created successfully!");
			mutate();
		} catch (error) {
			console.log(error);
		} finally {
			setisSaving(false);
		}
	};

	const rows = data?.data;

	const handleChangeStatus = async (test_id) => {
		mutate();
	};

	return (
		<CorpLayout>
			<h3 className="text-center font-bold text-3xl text-white">Hello!</h3>
			<h1 className="text-center font-bold text-4xl text-white">
				Below are the assessments
			</h1>
			<div className="flex flex-col gap-2 items-center justify-center py-2 mt-4">
				<Dialog
					renderTrigger={() => {
						return <Button>Create Test</Button>;
					}}
					title={"Create Test"}>
					<form onSubmit={handleSubmit}>
						<div className="flex-1 flex flex-col gap-y-4">
							<Select
								name="module"
								required
								className="w-full h-9 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors">
								{moduleListResp.isLoading ? (
									<option value={""} selected>
										loading...
									</option>
								) : (
									<option value={""} selected>
										Select Module
									</option>
								)}

								{moduleListResp?.data
									? moduleListResp?.data.map((v) => (
											<option key={v.id} value={v.module_name}>
												{v.module_name}
											</option>
									  ))
									: null}
							</Select>
							<Button type="submit" disabled={isSaving}>
								{isSaving ? "Saving..." : "Create Test"}
							</Button>
						</div>
						<div className="mt-4">
							<Link
								className="text-black underline text-md"
								to="/corp/test/create">
								Create your own module to have custom questions
							</Link>
						</div>
					</form>
				</Dialog>
				<div className="mw-[40%] max-w-full">
					<AssesmentTable rows={rows} onStatusChange={handleChangeStatus} />
				</div>
			</div>
		</CorpLayout>
	);
};

export default Dashboard;
