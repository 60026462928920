import { useEffect, useRef, useState } from "react";

let interval;

// const MAX_MINS_MSEC = 1000 * 60 * 1; //1 min
// const MAX_MINS_MSEC = 1000 * 10; //10Sec
// const MAX_MINS_MSEC = 1000 * 35; //35Sec

// let timer = MAX_MINS_MSEC;

function getTimerSpanEle(params) {
	try {
		return document.getElementById("timer-span");
	} catch (error) {
		console.error("Error accessing timer span element:", error);
		return null;
	}
}

const useTimer = (SEC = 35, onTimeUp) => {
	const MAX_MINS_MSEC = SEC * 1000;

	const timer = useRef(MAX_MINS_MSEC);

	function setTimerSpanEle(msec = 0) {
		const timerSpanEle = getTimerSpanEle();
		if (timerSpanEle) {
			// timer = msec;
			timer.current = msec;
			timerSpanEle.textContent = millisecondsToTime(msec);
		} else {
			console.error("Timer span element not found.");
		}
	}

	function startTimer(params) {
		console.log("Starting timer...");

		clearInterval(interval);
		setTimerSpanEle(MAX_MINS_MSEC);
		interval = setInterval(() => {
			timer.current = timer.current - 1000;
			if (timer.current < 0) {
				console.log("time up");
				onTimeUp?.();
				stopTimer();
				return;
			}
			setTimerSpanEle(timer.current);
		}, 1000);
	}

	function pauseTimer() {
		clearInterval(interval);
	}

	function stopTimer() {
		setTimerSpanEle(0);
		clearInterval(interval);
	}

	useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	return {
		startTimer,
		stopTimer,
		pauseTimer,
	};
};

export default useTimer;

function millisecondsToTime(s) {
	return new Date(s).toISOString().slice(14, -5);
}
