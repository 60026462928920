import React, { useState } from "react";
import { useNavigate, Navigate, Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";

import { login } from "../apis/users.apis";
import { useAuth } from "../context/AuthProvider";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";

import { Label } from "../components/ui/label";
import user_types from "../constants/user_types";
import * as storageUtils from "../utils/storage.utils";

import { getTestIdentifierData } from "../apis/quizes.apis";

function Login() {
	const [email, setemail] = useState("");
	const [password, setPassword] = useState("");
	const [isloading, setisloading] = useState(false);

	const [searchParams] = useSearchParams();

	const testIdentifier = searchParams.get("test_identifier");

	const { data: testData } = useSWR(
		testIdentifier ? `/testIdentifier/${testIdentifier}` : null,
		() => getTestIdentifierData(testIdentifier)
	);

	const navigate = useNavigate();
	const { setAuth, isAuth } = useAuth();

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			setisloading(true);
			event.preventDefault();

			const d = await login({
				email: email.trim(),
				password: password.trim(),
			});

			setAuth({
				...d.user_data,
				corpName: d.user_data.company_name,
				token: d.token,
				isAuth: true,
				accType: user_types.USER,
			});

			toast.success("Login successful!");

			if (testData?.module_name) {
				storageUtils.setTestData(testData);
				navigate("/quiz/" + testData.module_name + "/start");
				return;
			}

			navigate("/dashboard");
		} catch (error) {
			if (error.response?.data?.error) {
				toast.error(error.response?.data?.error);
				return;
			}

			toast.error("Something went wrong!");
		} finally {
			setisloading(false);
		}
	};

	if (isAuth) {
		return <Navigate to={"/dashboard"} />;
	}

	return (
		<section className="min-h-screen bg-gradient-to-b from-slate-50 to-slate-200 flex items-center justify-center">
			<div className="flex flex-col relative gap-6 items-center rounded-3xl  py-4 px-8 bg-white/50  border-t-2 shadow-sm border-white  ">
				{/* <Button className="flex mb-1 px-14 py-6 rounded-lg items-center justify-center w-[80%] text-md h-11 gap-2 bg-white text-black/70 font-medium">
					<img src="/google.svg" alt="googleicom" className="h-5 w-5" />{" "}
					Continue with Google
				</Button> */}
				{/* <div className="flex items-center">
					<div className="w-[100px] h-[1px] bg-slate-500/20"></div>
					<p className="px-3">OR</p>
					<div className="w-[100px] h-[1px] bg-slate-500/20"></div>
				</div> */}

				<form onSubmit={handleSubmit} className="space-y-3">
					<div className="space-y-1">
						<Label htmlFor="email">Email</Label>
						<Input
							type="email"
							id="email"
							value={email}
							onChange={(e) => setemail(e.target.value)}
						/>
					</div>
					<div className="space-y-1">
						<Label htmlFor="password">Password</Label>
						<Input
							type="password"
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<Button className="bg-black mt-3" type="submit" disabled={isloading}>
						{isloading ? "Loading..." : "Login"}
					</Button>
				</form>
				<div>
					<span className="text-sm text-slate-600">Dont have a account?</span>{" "}
					<span>
						<Link
							to={"/register" + window.location.search}
							className="text-sm font-medium text-zinc-700">
							Register here.
						</Link>
					</span>
				</div>
			</div>
		</section>
	);
}

export default Login;
