import React from "react";
import { Navigate, useParams } from "react-router-dom";
import useSWR from "swr";
import Spinner from "react-bootstrap/Spinner";

import UserLayout from "../layouts/UserLayout";

import { useAuth } from "../context/AuthProvider";
import { getTestIdentifierData } from "../apis/quizes.apis";
import * as storageUtils from "../utils/storage.utils";

const TestLink = () => {
	const { isAuth } = useAuth();
	const { testIdentifier } = useParams();

	const {
		data: testData,
		error,
		isLoading,
	} = useSWR(testIdentifier ? `/testIdentifier/${testIdentifier}` : null, () =>
		getTestIdentifierData(testIdentifier)
	);

	if (!isAuth) {
		return <Navigate to={"/login?test_identifier=" + testIdentifier} />;
	}

	if (testData?.module_name && testData?.status === "active") {
		storageUtils.setTestData(testData);
		return <Navigate to={"/quiz/" + testData.module_name + "/start"} />;
	}

	return (
		<UserLayout>
			<div className="flex justify-center items-center">
				{isLoading ? (
					<div className="text-white flex gap-2 justify-center items-center">
						<Spinner animation="border" role="status" color="#ffffff"></Spinner>
						<h1 className="text-white text-4xl">Processing...</h1>
					</div>
				) : null}

				{error && !isLoading ? (
					<div className="text-white flex gap-2 justify-center items-center">
						<h1 className="text-4xl text-red-100">Something went wrong!</h1>
					</div>
				) : null}

				{!error && !isLoading && testData?.status !== "active" ? (
					<div className="text-white flex gap-2 justify-center items-center">
						<h1 className="text-4xl text-red-100">Test deactivated!</h1>
					</div>
				) : null}
			</div>
		</UserLayout>
	);
};

export default TestLink;
