import secureStorage from "react-secure-storage";

export const KEYS = {
	USER: "hr_app@user",
	TEST_DATA: "hr_app@test",
};

export const getUser = () => {
	return secureStorage.getItem(KEYS.USER);
	// return localStorage.getItem(KEYS.USER);
	// return {
	// 	isAuth: true,
	// 	id: 2,
	// 	firstName: "John",
	// 	token: "str-token",
	// };
};

export const setUser = (user = {}) => {
	secureStorage.setItem(KEYS.USER, user);
	// localStorage.setItem(KEYS.USER, user);
};

export const removeUser = () => {
	secureStorage.removeItem(KEYS.USER);
	secureStorage.removeItem(KEYS.TEST_DATA);
	// localStorage.removeItem(KEYS.USER);
};

export const setTestData = (data = {}) => {
	secureStorage.setItem(KEYS.TEST_DATA, data);
};

export const getTestData = () => {
	return secureStorage.getItem(KEYS.TEST_DATA)
		? secureStorage.getItem(KEYS.TEST_DATA)
		: {
				id: 1,
				module_api: "https://newapi.aihirestudio.com/quiz/Human Resource",
				module_name: "Human Resource",
				company_name: "HOC",
				test_identifier: "none",
		  };
};
