import React from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../context/AuthProvider";
import UserLayout from "../layouts/UserLayout";
import * as storageUtils from "../utils/storage.utils";

const Home = () => {
	const { firstName } = useAuth();

	const tests = [storageUtils.getTestData()];
	return (
		<UserLayout>
			<section
				style={{ minHeight: "100vh", width: "100%" }}
				className="p-5 container-fluid d-flex flex-column  align-items-center text-white">
				<div className="mb-5">
					<h1 className="text-center text-4xl font-bold">
						Hello {firstName}!!
					</h1>
					<h5 className="text-center text-3xl">You can attempt below tests</h5>
				</div>

				<div className="d-flex justify-content-center align-items-center">
					<div
						className="row"
						style={{
							minWidth: "300px",
							width: "30vw",
						}}>
						{tests?.map((v) => {
							return (
								<Card key={v.id} id={v.module_name} title={v.module_name} />
							);
						})}
					</div>
				</div>
			</section>
		</UserLayout>
	);
};

export default Home;

const Card = ({ title, id }) => {
	return (
		<div className="col-12">
			<div className="card m-2">
				<div className="card-body">
					<h5 className="card-title text-black">Test - {id}</h5>
					<p className="card-text text-black">{title}</p>
					<Link to={`/quiz/${id}/start`} className="card-link underline">
						Take Test
					</Link>
				</div>
			</div>
		</div>
	);
};
