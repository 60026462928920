import React from "react";
import useSWR from "swr";
import { Spinner } from "react-bootstrap";

import HomePageHeader from "../components/HomePageHeader";
import { getResult } from "../apis/responses.apis";
import UserLayout from "../layouts/UserLayout";

function getMesssae(score) {
	const intScore = parseInt(score);

	return intScore > 75 ? "that is a great score" : "that is an average score";
}

// const data = {
// 	user_id: 7,
// 	score: 80,
// };

const ResultDetails = () => {
	const { data, error, isLoading } = useSWR("/share_data", getResult);

	return (
		<UserLayout>
			<section
				style={{
					minHeight: "100vh",
				}}
				className="text-white">
				<div style={{ position: "relative", height: "40px" }} />
				<div className="container" style={{ padding: "18px" }}>
					<center>
						{isLoading ? (
							<center>
								<Spinner /> <span>Loading...</span>
							</center>
						) : null}
					</center>
					<div>
						{data ? (
							<>
								<h1 className="text-3xl font-bold mb-4 text-center">
									Steadiness Assessment Result
								</h1>

								<center>
									<img
										className="shadow-sm p-2 rounded-3xl bg-gradient-to-b from-slate-50 to-slate-200"
										style={{
											height: "300px",
										}}
										src={
											// "https://api.aihirestudio.com/image_api/" + data.user_id
											"https://media.istockphoto.com/id/1363859252/photo/young-man-talking-on-a-video-call-and-looking-at-the-camera.jpg?s=612x612&w=0&k=20&c=tT3WwYer-qBjfvH3afL4ESHjnQ3XA-RCsjrNgsCry2M="
										}
										alt="Your SVG"
									/>
								</center>
								{/* <h5 className="mt-4 text-center">
									Here is the score for your first round of hiring interviews
								</h5> */}
								<center>
									<div className="my-4">
										<h6 className="text-2xl">Your Steadiness Score</h6>
										<h1 className="text-5xl font-bold">{data.score}%</h1>
									</div>
									{/* <div
										className="rounded"
										style={{
											border: "2px solid white",
											margin: "30px 0",
											padding: "8px",
											width: "40%",
										}}>
										<h2>Steadiness Score: {data.score}%</h2>
									</div> */}
									<h5 className="text-2xl text-center">
										You have scored <strong>{data.score}%</strong>,{" "}
										{getMesssae(data.score)}. Any score above 75 demonstrates
										strong steadiness.
									</h5>
								</center>
							</>
						) : (
							<h5 className="text-3xl font-bold text-center">No Data Found</h5>
						)}
					</div>
				</div>
			</section>
		</UserLayout>
	);
};

export default ResultDetails;
