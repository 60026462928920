import React from "react";
import CorpNavbar from "../components/CorpNavbar";
import BgCover from "../components/BgCover";

const CorpLayout = ({ children }) => {
	return (
		<main>
			<BgCover />
			<CorpNavbar />
			<div className="p-2">{children}</div>
		</main>
	);
};

export default CorpLayout;
