import React, { useState } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { login } from "../../apis/corp.apis";
import { useAuth } from "../../context/AuthProvider";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";

import { Label } from "../../components/ui/label";
import user_types from "../../constants/user_types";

function Login() {
	const [email, setemail] = useState("");
	const [password, setPassword] = useState("");
	const [isloading, setisloading] = useState(false);

	const navigate = useNavigate();
	const { setAuth, isAuth } = useAuth();

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			setisloading(true);
			event.preventDefault();

			const d = await login({
				corp_email: email.trim(),
				password: password.trim(),
			});

			setAuth({
				firstName: d.corp_user_data.firstName,
				lastName: d.corp_user_data.lastName,
				corpName: d.corp_user_data.corpName,
				email: d.corp_user_data.email,
				accType: user_types.CORP,
				token: d.token,
				isAuth: true,
				id: d.corp_user_data.id,
			});

			toast.success("Login successful!");

			navigate("/corp");
		} catch (error) {
			if (error.response?.data?.error) {
				toast.error(error.response?.data?.error);
				return;
			}

			toast.error("Something went wrong!");
		} finally {
			setisloading(false);
		}
	};

	if (isAuth) {
		return <Navigate to={"/dashboard"} />;
	}

	return (
		<section className="min-h-screen bg-gradient-to-b from-slate-50 to-slate-200 flex flex-col items-center justify-center">
			<h1 className="text-5xl md:text-5xl text-center lg:text-5xl font-bold mb-8 max-w-[900px]">
				Welcome to
				<span className="text-[#6666FF]"> AI</span>{" "}
				<span className="text-[#BA33FF]">Studio</span> App
			</h1>
			<h1 className="text-2xl md:text-2xl text-center lg:text-2xl font-bold mb-8 max-w-[900px]">
				Corporate Login
			</h1>
			<div className="flex flex-col relative gap-6 items-center rounded-3xl  py-4 px-8 bg-white/50  border-t-2 shadow-sm border-white  ">
				<form onSubmit={handleSubmit} className="space-y-3">
					<div className="space-y-1">
						<Label htmlFor="email">Email</Label>
						<Input
							type="email"
							id="email"
							value={email}
							onChange={(e) => setemail(e.target.value)}
						/>
					</div>
					<div className="space-y-1">
						<Label htmlFor="password">Password</Label>
						<Input
							type="password"
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<Button className="bg-black mt-3" type="submit" disabled={isloading}>
						{isloading ? "Loading..." : "Login"}
					</Button>
				</form>
				<div>
					<span className="text-sm text-slate-600">Dont have an account?</span>{" "}
					<span>
						<Link
							to="/corp-auth/register"
							className="text-sm font-medium text-zinc-700">
							Register here.
						</Link>
					</span>
				</div>
			</div>
		</section>
	);
}

export default Login;
