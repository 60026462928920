import React, { useState, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { endQuiz as endQuizApi } from "../apis/quizes.apis";
import { Button } from "../components/ui/button";

import * as storageUtils from "../utils/storage.utils";

const TestNav = ({ stopRecording }) => {
	const { id: quizId } = useParams();
	const [isloading, setisloading] = useState(false);

	const testData = storageUtils.getTestData();

	const handleEndTest = async () => {
		if (!window.confirm("are you sure you want to end this test?")) {
			return;
		}

		try {
			setisloading(true);

			stopRecording();

			const d = await endQuizApi(quizId);
			toast.success("Test ended!");

			window.location.href = "/dashboard";
		} catch (error) {
			console.log(error);
			if (error.response?.data?.message) {
				toast.error(error.response?.data?.message);
				return;
			}

			toast.error("something went wrong!");
		} finally {
			setisloading(false);
		}
	};

	return (
		<nav className="bg-gray-200 shadow shadow-gray-300 w-100 px-8 md:px-auto">
			<div className="md:h-16 h-28 mx-auto md:px-4 container flex items-center justify-between flex-wrap md:flex-nowrap">
				<div className="flex items-center gap-2 text-indigo-500 md:order-1">
					<h1 className="text-2xl capitalize">{testData.company_name}</h1>
				</div>
				{/* <div className="text-gray-500 order-3 w-full md:w-auto md:order-2">
					<ul className="flex font-semibold justify-between">
						<li className="md:px-4 md:py-2 text-indigo-500">
							<Link to={"/"}>Dashboard</Link>
						</li>
						<li className="md:px-4 md:py-2 hover:text-indigo-400">
							<Link href="#">Search</Link>
						</li>
						<li className="md:px-4 md:py-2 hover:text-indigo-400">
							<Link href="#">Explore</Link>
						</li>
						<li className="md:px-4 md:py-2 hover:text-indigo-400">
							<Link href="#">About</Link>
						</li>
						<li className="md:px-4 md:py-2 hover:text-indigo-400">
							<Link href="#">Contact</Link>
						</li>
					</ul>
				</div> */}
				<div className="order-2 md:order-3">
					<Button
						onClick={handleEndTest}
						variant="destructive"
						disabled={isloading}>
						{isloading ? "Loading..." : "End Test"}
					</Button>
				</div>
			</div>
		</nav>
	);
};

export default TestNav;
