import axios from "../libs/axios.lib";
import * as secureStorage from "../utils/storage.utils";

export const getQuizes = () =>
	new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve([{ id: "default", title: "Steadiness Assessment Questions" }]);
		}, 1000);
	});

export const getTestIdentifierData = async (testIdentifier = "") => {
	const { data } = await axios.get(`/corporate/get_test/${testIdentifier}`);
	return data?.data[0];
};

export const startQuiz = async (quizId) => {
	const { token } = secureStorage.getUser();

	return;
};
export const endQuiz = async (quizId) => {
	const { token } = secureStorage.getUser();

	return;
};

export const getQuestions = async (url) => {
	const { token } = secureStorage.getUser();

	// const { data } = await axios.get("http://localhost:3000/quiz.json", {
	// 	headers: {
	// 		Authorization: "Bearer " + token,
	// 	},
	// });
	const { data } = await axios.get(url, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});
	return data;
};
export const getAnswerTime = async (url) => {
	const { token } = secureStorage.getUser();

	const { data } = await axios.get("answer_time", {
		headers: {
			Authorization: "Bearer " + token,
		},
	});
	return data;
};

export const getFileCounts = async () => {
	const { token, id } = secureStorage.getUser();

	const { data } = await axios.get("count_files/" + id, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	const filecount = parseInt(data.message);

	return isNaN(filecount) ? 0 : filecount;
};

export const deleteVideoFiles = async () => {
	const { token, id } = secureStorage.getUser();

	const { data } = await axios.get("delete_files/" + id, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});
	return data;
};
