import axios from "../libs/axios.lib";
import * as secureStorage from "../utils/storage.utils";

export const register = async (body = {}) => {
	const { data } = await axios.post(`corporate/register`, body);
	return data;
};

export const login = async (body = { email: "", password: "" }) => {
	const { data } = await axios.post(`corporate/login`, body);
	return data;
};

export const getTests = async () => {
	const { id } = secureStorage.getUser();

	const { data } = await axios.get(`/corporate/get_all_tests/${id}`);
	return data;
};

export const getAllModules = async () => {
	const { data } = await axios.get(`/get/modules`);
	return data.data;
};

export const updateTestStatus = async (test_id) => {
	const { id } = secureStorage.getUser();
	const { data } =
		await axios.get(`/corporate/update_test_status/${id}/${test_id}
`);
	return data;
};

export const createTest = async (module_name) => {
	const { id, corpName } = secureStorage.getUser();

	const { data } = await axios.post(`/corporate/create_test`, {
		corp_user_id: id,
		company_name: corpName,
		module_name: module_name,
	});

	return data?.data;
};

export const getResults = async (test_id) => {
	const { data } = await axios.get(`/corporate/score_history_data/${test_id}`);
	return data.data;
};
